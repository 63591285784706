import { gsap } from "gsap/dist/gsap";
import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { SplitText } from "gsap/dist/SplitText";
import { ScrollSmoother } from "gsap/dist/ScrollSmoother";
import { lockScroll } from "./lockscroll";
import { buildParallax } from "./parallax";
import { buildSections, heroTitle } from "./sections";

gsap.registerPlugin(CSSRulePlugin, ScrollToPlugin, ScrollTrigger, SplitText, ScrollSmoother);

const initBuild = () => {
    buildSections();
    lockScroll();
    let cover = document.querySelector(".cover-container");
    let hero = document.querySelector(".hero--img");
    let circleText = document.querySelector(".circle--text");
    gsap.timeline({
        defaults: {
            duration:1,
            ease:"power2"
        }
    })
    .to(cover, { scaleY:0, delay:.5, backgroundColor:'rgb(0,0,0)', transformOrigin:"bottom", onComplete:lockScroll } )
    .to(cover, { autoAlpha:0 })
    .from(hero, { scale:1.5, duration:2, transformOrigin:"center" },0);
    gsap.to(circleText, { duration:15, rotation:360, repeat:-1, ease:"linear"  });
}

// window.onbeforeunload = function () {
//     window.scrollTo(0, 0);
//   }

window.addEventListener('load', function () {
      
    //   const smoother = ScrollSmoother.create({
    //     smooth: 1,
    //     effects: true,
    //     normalizeScroll: true,
    //     ignoreMobileResize: true,
    //     preventDefault: true
    //   });

      initBuild();

}, false);

//   let timeout 
//   let delay = 250

//   window.addEventListener('resize', function() {
// 	buildSections();
//     clearTimeout(timeout);
//     timeout = setTimeout(buildSections, delay);
// });



import { gsap } from "gsap/dist/gsap";
import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(CSSRulePlugin, ScrollTrigger);

export const buildParallax = () => {
  
  ScrollTrigger.matchMedia({
    // desktop
    "(min-width: 601px)": function() {
      gsap.utils.toArray(".parallax--wrapper .parallax").forEach((item) => {
        const heightDiff = item.offsetHeight - item.parentElement.offsetHeight;
        gsap.timeline({
            scrollTrigger: {
              trigger: item.parentElement,
              scrub: true,
              invalidateOnRefresh: true
            }})
            .fromTo(item, { y: -heightDiff }, { y: 0, ease: "none" });
      });
    }, 
    // small screen
    "(max-width: 600px)": function() {
      return;
    } 
  });   
}
buildParallax();
import { gsap } from "gsap/dist/gsap";
import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { SplitText } from "gsap/dist/SplitText";

gsap.registerPlugin(CSSRulePlugin, ScrollToPlugin, ScrollTrigger, SplitText);

export const buildSections = () => {

    const initMeter = () => {

        let arrow = document.querySelector(".pointer--arrow");

        ScrollTrigger.create({
        trigger:arrow,
        start:"center center",
        endTrigger:"#contact",
        end: "center center",
        scrub: true,
        pin: true,
        refreshPriority: -1
        })

    }

    const initIntro = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 601px)": function() {

                let intro = document.querySelector("#intro");
                let title = document.querySelector("h2");
                let titleSplitParent = new SplitText(title, {type:"words", wordsClass:"wordsParent"});
                let titleSplitChild = new SplitText(title, {type:"words", wordsClass:"wordsChild"});
                let text = document.querySelector(".text-wrapper");
                let textSplitChild = new SplitText(text, { type: "lines", linesClass:"linesChild"});
                let textSplitParent = new SplitText(text, { type: "lines", linesClass:"linesParent"});
                let circle = document.querySelector(".circle--container");

                gsap.timeline({
                    defaults: {
                        duration:1,
                        ease: "power2"
                    },
                    scrollTrigger: {
                        trigger: intro,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                    }
                })
                .from(circle, { y:100, opacity:0 })
                .from(titleSplitChild.words, { yPercent:100 },0)
                .from(textSplitChild.lines, { duration:1, yPercent:100, stagger:0.1 },0);
            }, 
            "(max-width: 600px)": function() {

                let intro = document.querySelector("#intro");
                let title = document.querySelector("h2");
                let text = document.querySelector(".text-wrapper");

                gsap.timeline({
                    defaults: {
                        duration:1,
                        ease: "power2"
                    },
                    scrollTrigger: {
                        trigger: intro,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                        
                    }
                })
                .from(title, { y:50, opacity:0 })
                .from(text, {y:50, opacity:0 },0);
            }
            
        });

    }
    

      
    const initDivisions = () => {

        let division = document.querySelectorAll(".division");
        ScrollTrigger.matchMedia({
            
            "(min-width: 601px)": function() {
                division.forEach( (element) => {

                    let title = element.querySelector(".section--title");
                    let titleSplitParent = new SplitText(title, {type:"words", wordsClass:"titleParent"});
                    let titleSplitChild = new SplitText(title, {type:"words", wordsClass:"titleChild"});
                    let img = element.querySelector(".parallax--wrapper");
                    let text = element.querySelector('.text-wrapper');
                    let textSplitChild = new SplitText(text, { type:"lines", linesClass:"textChild" });
                    let textSplitParent = new SplitText(text, { type:"lines", linesClass:"textParent" });
                    let index = element.querySelector('.index--nr');
                    let indexline = element.querySelector('.index--line');   
                    let indexsplit = new SplitText(index, { type:"chars", charsClass:"indexChars" } );
            
                    gsap.set(indexsplit.chars, { transformOrigin: "center center -100px", backfaceVisibility:"hidden" });
            
                    let tl = gsap.timeline({ defaults: { duration:1, ease:'power2' } });
                    tl.from(img, { opacity:0, y:100 })
                    .from(titleSplitChild.words, { yPercent:100 },0)
                    .from(textSplitChild.lines, { duration:1, yPercent:100, stagger:0.1 },0)
                    .from(indexline, { y:-100, opacity:0 },0)
                    .from(indexsplit.chars, {duration:1, opacity:0, scale:0, rotationX:360, stagger: 0.1 },0);
            
                    ScrollTrigger.create( {
                        trigger: element,
                        start:"top 60%",
                        toggleActions:"restart none none reverse",
                        animation: tl        
                    })
            
                });
            
            }, 
            
            "(max-width: 600px)": function() {
                division.forEach( (element) => {

                    let title = element.querySelector(".section--title");
                    let text = element.querySelector('.text-wrapper');
                    let img = element.querySelector(".parallax--wrapper");
                    let index = element.querySelector('.index--nr');
                    let indexline = element.querySelector('.index--line');
            
                    let tl = gsap.timeline({ defaults: { duration:1, ease:'power2' } });
                    tl.from(img, { y:50, opacity:0 })
                    .from(title, { y:50, opacity:0 },0)
                    .from(text, { y:50, opacity:0 }, 0)
                    .from(index, { y:50, opacity:0 }, 0)
                    .from(indexline, { opacity:0  }, 0);
            
                    ScrollTrigger.create( {
                        trigger: element,
                        start:"top 60%",
                        toggleActions:"restart none none reverse",
                        animation: tl
                            
                    })
            
                });
            
            }
            
        });

    }
    

    const initQuote = () => {
        ScrollTrigger.matchMedia({
            
            "(min-width: 601px)": function() {

                let quote = document.querySelector("#about");
                let text = document.querySelector(".quote");
                let textSplitParent = new SplitText(text, {type:"words", wordsClass:"wordsParent"});
                let textSplitChild = new SplitText(text, {type:"words", wordsClass:"wordsChild"});
                let img = document.querySelector("#about img");

                gsap.timeline({
                    scrollTrigger: {
                        trigger: quote,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                    }
                })
                .from(textSplitChild.words, { duration:1, yPercent:100, ease:"power2" })
                .from(img, { duration:1, rotationY:25, y:250, opacity:0, ease:"power2" },0);
            }, 
            
            "(max-width: 600px)": function() {

                let quote = document.querySelector("#about");
                let text = document.querySelector(".quote");
                let img = document.querySelector("#about img");

                gsap.timeline({
                    scrollTrigger: {
                        trigger: quote,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                    }
                })
                .from(text, { y:50, duration:1, opacity:0, ease:"linear" })
                .from(img, { y:50, duration:1, opacity:0, ease:"linear"},0);
            }
            
        });

    }



    const initClients = () => {

        ScrollTrigger.matchMedia({  
            "(min-width: 601px)": function() {

                let clients = document.querySelector('#clients');
                let title = document.querySelector('.client--title');
                let titleSplitParent = new SplitText(title, {type:"words", wordsClass:"wordsParent"});
                let titleSplitChild = new SplitText(title, {type:"words", wordsClass:"wordsChild"});
                let clientList = document.querySelectorAll('.client--list li');
                let clientImg = document.querySelectorAll('.grid--item picture');

                gsap.timeline({
                    defaults: {
                        duration:1,
                        ease: "power2"
                    },
                    scrollTrigger: {
                        trigger: clients,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                    } 
                })
                .from(titleSplitChild.words, { duration:1, yPercent:100, ease:"power2" } )
                .from( clientList, { opacity:0, y:50, stagger: 0.1 },0)
                .from( clientImg, { scale:0,  stagger:0.1 },0);
            },   
            "(max-width: 600px)": function() {

                let clients = document.querySelector('#clients');
                let title = document.querySelector('.client--title');
                let clientList = document.querySelectorAll('.client--list li');
                let clientImg = document.querySelectorAll('.grid--item picture');

                gsap.timeline({
                    defaults: {
                        duration:1,
                        ease: "power2"
                    },
                    scrollTrigger: {
                        trigger: clients,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                    } 
                })
                .from( title, { y:50, opacity:0 } )
                .from( clientList, { y:50, opacity:0 },0)
                .from( clientImg, { opacity:0, stagger:0.1 },0);
            }
            
        });

    }

    
    const initContact = () => {
        ScrollTrigger.matchMedia({
            
            "(min-width: 601px)": function() {

                let contact = document.querySelector("#contact");
                let title = document.querySelector(".contact--title");
                let titleSplitParent = new SplitText(title,  {type:"words", wordsClass:"titleParent"});
                let titleSplitChild = new SplitText(title,  {type:"words", wordsClass:"titleChild"});
                let button = document.querySelector(".contact-btn");
                let buttonSplitParent = new SplitText(button, {type:"words", wordsClass:"wordsParent"});
                let buttonSplitChild = new SplitText(button, {type:"words", wordsClass:"wordsChild"});

                gsap.timeline({
                    defaults: {
                        duration:1,
                        ease:"power2"
                    },
                    scrollTrigger: {
                        trigger: contact,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                        
                    }
                })
                .from(titleSplitChild.words, { yPercent:100  })
                .from(buttonSplitChild.words, { yPercent:100 },0)
            }, 
            
            "(max-width: 600px)": function() {

                let contact = document.querySelector("#contact");
                let title = document.querySelector(".contact--title");
                let button = document.querySelector(".contact-btn");
                
                gsap.timeline({
                    defaults: {
                        duration:1,
                        ease:"power2"
                    },
                    scrollTrigger: {
                        trigger: contact,
                        start:"top 60%",
                        toggleActions:"restart none none reverse"
                        
                    }
                })
                .from(title, { y:50, opacity:0 })
                .from(button, { opacity:0, duration:.5 },0)
            }
        
        });

    }

    initMeter();
    initIntro();
    initDivisions();
    initQuote();
    initClients();
    initContact();

    function killAll() {
        // splitLines.revert()
        // ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
        initMeter();
        initIntro();
        initDivisions();
        initQuote();
        initClients();
        initContact();
      }

    function debounce(func){
        var timer;
        return function(event){
          if(timer) clearTimeout(timer);
          timer = setTimeout(func,300,event);
        };
      }

    // window.addEventListener("resize", debounce(function(e){
    //     console.log("end of resizing")
    //     killAll();
    // }));

}

